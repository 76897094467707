var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canViewHorizontalNavMenuLink(_vm.item)
    ? _c(
        "li",
        {
          class: {
            active: _vm.isActive,
            disabled: _vm.item.disabled,
          },
        },
        [
          _c(
            "b-link",
            _vm._b(
              { staticClass: "dropdown-item" },
              "b-link",
              _vm.linkProps,
              false
            ),
            [
              _c("feather-icon", {
                attrs: { icon: _vm.item.icon || "CircleIcon", size: "24" },
              }),
              _c("span", { staticClass: "menu-title" }, [
                _vm._v(_vm._s(_vm.t(_vm.item.title))),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }