var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout-horizontal",
    {
      scopedSlots: _vm._u([
        {
          key: "navbar",
          fn: function (ref) {
            var toggleVerticalMenuActive = ref.toggleVerticalMenuActive
            return [
              _c("app-navbar-horizontal-layout-brand"),
              _c("navbar", {
                attrs: {
                  "toggle-vertical-menu-active": toggleVerticalMenuActive,
                },
              }),
            ]
          },
        },
      ]),
    },
    [_c("router-view")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }